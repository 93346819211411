<div
  style="
    width: 100%;
    height: 100%;
    background-color: #092556;
    display: flex;
    align-items: center;
    justify-content: center;
  "
>
  <div
    class="w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12"
    style="
      padding: 20px;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <div
      style="
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 30px;
        border: 1px solid black;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          margin-bottom: 20px;
        "
      >
        <img src="./assets/images/logo.svg" alt="Logo" style="height: 80px" />
      </div>
      <div style="height: 50px"></div>
      <div
        class="grid grid-cols-1 gap-1 place-content-center"
        style="height: calc(100% - 240px); width: 100%"
      >
        <form
          *ngIf="loginForm"
          (keyup.enter)="signIn()"
          [formGroup]="loginForm"
        >
          <div style="width: 100%">
            <div class="grid place-items-center">
              <mat-form-field appearance="outline" style="width: 60%">
                <mat-label>Email</mat-label>
                <input
                  matInput
                  formControlName="email"
                  placeholder="example@siteassistant.it"
                />
                <mat-icon matSuffix>mail</mat-icon>
              </mat-form-field>
            </div>
          </div>

          <div style="width: 100%">
            <div class="grid place-items-center">
              <mat-form-field appearance="outline" style="width: 60%">
                <mat-label>Password</mat-label>
                <input
                  matInput
                  formControlName="password"
                  [type]="hide ? 'password' : 'text'"
                />
                <button
                  mat-icon-button
                  type="button"
                  matSuffix
                  (keyup.enter)="signIn()"
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                  <mat-icon>{{
                    hide ? "visibility_off" : "visibility"
                  }}</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 20px;
              margin-bottom: 20px;
            "
          >
            <button
              mat-raised-button
              type="submit"
              (click)="signIn()"
              style="
                color: white;
                background-color: #0064eb;
                width: 200px;
                height: 50px;
                border-radius: 30px;
              "
            >
              ACCEDI
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
