import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/loader.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild(MatPaginator) 'paginator': MatPaginator;
  @ViewChild(MatSort) 'sort': MatSort;

  dataList: any;
  users: any[] = [];
  profiles: any[] = [];
  sites: any[] = [];
  inspections: any[] = [];
  arrayToTable: any[] = [];

  displayedColumns: string[] = [
    'userId',
    'name',
    'email',
    'item_price_id',
    'creation_date',
    'last_authentication_date',
    'sites',
    'estimatedCost',
    'avgEstimatedCost',
    'inspNumber',
    'totalAmount',
    'avgAmount',
    'actions',
  ];

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  constructor(private route: ActivatedRoute, public loaderSvc: LoaderService) {}

  ngOnInit(): void {
    this.dataList = this.route.snapshot.data['dataList'];
    this.users = this.dataList.users;
    this.profiles = this.dataList.profiles;
    this.sites = this.dataList.sites;
    this.inspections = this.dataList.inspections;
    this.loaderSvc.isLoading.next(false);

    let sortedUsersArray = this.users.sort((a, b) => a._id - b._id);
    let sortedProfilesArray = this.profiles.sort((a, b) => a.userId - b.userId);
    let sortedSitesArray = this.sites.sort((a, b) => a.userId - b.userId);
    let sortedInspectionsArray = this.inspections.sort(
      (a, b) => a.userId - b.userId
    );
    this.arrayToTable = sortedUsersArray.map((user, index) => {
      return {
        ...user,
        ...sortedProfilesArray[index],
        ...sortedSitesArray[index],
        ...sortedInspectionsArray[index],
        creation_date: user.creation_date * 1000,
        last_authentication_date: user.last_authentication_date * 1000,
        item_price_id: sortedProfilesArray[index]?.subscription.item_price_id,
      };
    });

    this.dataSource = new MatTableDataSource(this.arrayToTable);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
