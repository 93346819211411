import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/loader.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(public loaderSvc: LoaderService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.loaderSvc.isLoading.next(false);
    }, 300);
  }

  startLoading() {
    this.loaderSvc.isLoading.next(true);
  }

  fakeLoading() {
    this.loaderSvc.isLoading.next(true);
    setTimeout(() => {
      this.loaderSvc.isLoading.next(false);
    }, 300);
  }
}
