import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader.service';
import { RealmService } from 'src/app/realm.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  name: string = '';
  constructor(
    private _realmSvc: RealmService,
    public loaderSvc: LoaderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.router.navigate(['/main/home']);
    let customData: any = this._realmSvc.currentUser?.customData;
    this.name = customData.email;
    this.loaderSvc.isLoading.next(false);
  }

  async signOut() {
    await this._realmSvc.signOut();
  }

  startLoading() {
    this.loaderSvc.isLoading.next(true);
  }
}
