<div class="grid grid-cols-4 gap-4" style="height: fit-content; width: 100%">
  <button
    mat-button
    (click)="fakeLoading()"
    [routerLink]="['/main/home']"
    style="
      height: 200px;
      width: 100%;
      background-color: #0064eb;
      color: white;
      border-radius: 30px;
      font-size: 22px;
    "
  >
    <mat-icon style="margin-right: 10px; scale: 1.5">home</mat-icon>
    Home
  </button>
  <button
    mat-button
    (click)="startLoading()"
    [routerLink]="['/main/dashboard']"
    style="
      height: 200px;
      width: 100%;
      background-color: #0064eb;
      color: white;
      border-radius: 30px;
      font-size: 22px;
    "
  >
    <mat-icon style="margin-right: 10px; scale: 1.5">dashboard</mat-icon>
    Dashboard
  </button>
</div>
