import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { RealmService } from './realm.service';

@Injectable({
  providedIn: 'root',
})
export class UsersResolver implements Resolve<any> {
  constructor(private http: HttpClient, private _realmSvc: RealmService) {}

  async resolve(): Promise<any> {
    try {
      this._realmSvc.currentUser?.refreshAccessToken();
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this._realmSvc.currentUser?.accessToken}`,
      });

      const requestOptions = { headers: headers };

      let usersList = await firstValueFrom(
        this.http.get(
          'http://report-dev.siteassistant.it:1880/v1/dashboard',
          requestOptions
        )
      );

      return usersList;
    } catch (error) {
      return error;
    }
  }
}
