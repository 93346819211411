import * as Realm from 'realm-web';

import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class RealmService {
  private app = new Realm.App({ id: environment.realmAppId });

  get isLoggedIn(): boolean {
    return this.app.currentUser != null && this.app.currentUser.isLoggedIn;
  }

  private isUserLogged = new BehaviorSubject(this.isLoggedIn);

  get isUserLogged$() {
    return this.isUserLogged.asObservable();
  }

  get currentUser() {
    return this.app.currentUser;
  }

  constructor(private _router: Router) {}

  async signIn(email: string, password: string) {
    const realm_cred = Realm.Credentials.emailPassword(email, password);
    try {
      let loginResult = await this.app.logIn(realm_cred);
      this.isUserLogged.next(true);
      this._router.navigate(['/main/home']);
      return loginResult;
    } catch (error) {
      this.isUserLogged.next(false);
      throw error;
    }
  }

  signOut() {
    let loginResult = this.app.currentUser?.logOut();
    this.isUserLogged.next(false);
    this._router.navigate(['/login']);
    return loginResult;
  }
}
