<div style="height: 100%; width: 100%">
  <mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="side" opened>
      <div
        style="
          padding: 16px;
          height: 304px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <img
            src="./assets/images/logo-white.svg"
            alt="Logo"
            style="height: 150px"
          />
        </div>
      </div>
      <div
        style="
          padding: 16px;
          height: calc(100% - 304px);
          width: 100%;

          display: flex;
          flex-direction: column;
          flex: 1 0 auto;
          -webkit-user-select: none;
          user-select: none;
        "
      >
        <button
          mat-flat-button
          (click)="startLoading()"
          [routerLink]="['home']"
          style="
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 10px 16px;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-decoration: none;
            border-radius: 6px;
            background-color: #1f3764;
            color: white;
            margin-bottom: 10px;
          "
        >
          <mat-icon
            style="
              display: inline-flex !important;
              align-items: center;
              justify-content: center;
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              font-size: 24px;
              line-height: 24px;
              margin-right: 16px;
            "
            >home</mat-icon
          >
          <span>Home</span>
        </button>
        <button
          mat-flat-button
          (click)="startLoading()"
          [routerLink]="['dashboard']"
          style="
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 10px 16px;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-decoration: none;
            border-radius: 6px;
            background-color: #1f3764;
            color: white;
          "
        >
          <mat-icon
            style="
              display: inline-flex !important;
              align-items: center;
              justify-content: center;
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              font-size: 24px;
              line-height: 24px;
              margin-right: 16px;
            "
            >dashboard</mat-icon
          >
          <span>Dashboard</span>
        </button>
      </div>
    </mat-drawer>
    <div class="example-sidenav-content">
      <mat-toolbar color="primary">
        <button
          mat-icon-button
          (click)="drawer.toggle()"
          class="example-icon"
          aria-label="Example icon-button with menu icon"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <span class="example-spacer"></span>
        <div style="color: #0064eb; font-size: 18px; margin-right: 10px">
          {{ name }}
        </div>
        <button
          mat-icon-button
          (click)="signOut()"
          class="example-icon"
          aria-label="Example icon-button with share icon"
        >
          <mat-icon>logout</mat-icon>
        </button>
      </mat-toolbar>
      <div *ngIf="loaderSvc.isLoading | async">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div style="height: calc(100% - 68px); width: 100%; padding: 30px">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-drawer-container>
</div>
