<div class="grid grid-cols-4 gap-4">
  <div class="col-span-3">
    <mat-form-field appearance="standard">
      <mat-label>Filter</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Ex. Mia"
        #input
      />
    </mat-form-field>
  </div>
  <div class="col-span-1 place-self-center">
    <button
      mat-flat-button
      color="primary"
      (click)="exporter.exportTable('csv')"
    >
      SCARICA I DATI
    </button>
  </div>
</div>

<div class="mat-elevation-z8">
  <table
    mat-table
    matTableExporter
    [dataSource]="dataSource"
    #exporter="matTableExporter"
    matSort
  >
    <ng-container matColumnDef="userId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let row">{{ row.userId }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let row">{{ row.email }}</td>
    </ng-container>

    <ng-container matColumnDef="item_price_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Abbonamento</th>
      <td mat-cell *matCellDef="let row">
        {{ row.item_price_id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="creation_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data creazione</th>
      <td mat-cell *matCellDef="let row">
        {{ row.creation_date | date : "shortDate" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="last_authentication_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ultimo accesso</th>
      <td mat-cell *matCellDef="let row">
        {{ row.last_authentication_date | date : "short" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sites">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>N. Cantieri</th>
      <td mat-cell *matCellDef="let row">{{ row.sites }}</td>
    </ng-container>

    <ng-container matColumnDef="estimatedCost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Imp. tot. cantieri
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.estimatedCost | currency : "EUR" : "symbol" : "1.0" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="avgEstimatedCost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Imp. med. cantieri
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.avgEstimatedCost | currency : "EUR" : "symbol" : "1.0" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="inspNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>N. Rilievi</th>
      <td mat-cell *matCellDef="let row">{{ row.inspNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="totalAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Imp. tot. rilievi
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.totalAmount | currency : "EUR" : "symbol" : "1.0" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="avgAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Imp. med. rilievi
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.avgAmount | currency : "EUR" : "symbol" : "1.0" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let row">
        <button
          mat-icon-button
          color="primary"
          aria-label="Example icon button with a home icon"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        No data matching the filter "{{ input.value }}"
      </td>
    </tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page of users"
  >
  </mat-paginator>
</div>
